import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import SectionLayout from "../../components/SectionLayout";
import styled from "styled-components";
import { FiE } from "react-icons";
import { FiExternalLink } from "react-icons/fi";

const tools = [
  {
    isInternal: true,
    to: "/tools/above-the-fold",
    title: "Above the Fold plugin",
    description:
      "Mark your designs with a layer that indicates where is the Above the Fold portion.",
  },
  {
    isInternal: true,

    to: "/tools/use-cases",
    title: "Use Cases for VisualEyes",
    description:
      "Get some inspiration to help get you started with your own tests.",
  },
  {
    isInternal: false,

    to: "https://blog.visualeyes.design/?ref=visualeyes-tools",
    title: "Blog",
    description:
      "Discover the latest news, product updates, and design process insights from VisualEyes.",
  },

  {
    isInternal: false,

    to: "https://www.loceye.io/?ref=visualeyes-tools",
    title: "Loceye",
    description:
      "See through your customer eyes with remote eye-tracking studies.",
  },
];

const ToolsSection = ({ row, col, textStyle, headerStyle }) => {
  return (
    <SectionLayout
      sectionId="tools"
      title="Resources & Tools"
      description="VisualEyes is all about improving the workflow for designers, product managers and marketeers. Here are some tools and resources that can help you get your job done!"
    >
      <Box className="row" {...row} justifyContent="center">
        <Box className="col" {...col} flexDirection="column" flexBox={true}>
          {tools.map((tool, index) =>
            tool.isInternal ? (
              <StyledLink
                id={`resources-${tool.title.split(" ").join("-")}`}
                to={tool.to}
                style={{
                  marginBottom: index < tools.length - 1 ? "1.5rem" : 0,
                }}
              >
                <Heading
                  style={headerStyle}
                  content={
                    <Row>
                      {tool.title}

                      <FiExternalLink
                        size={12}
                        style={{ marginLeft: ".5rem", opacity: ".5" }}
                      />
                    </Row>
                  }
                />
                <Text as="h3" style={textStyle} content={tool.description} />
              </StyledLink>
            ) : (
              <StyledA
                id={`resources-${tool.title.split(" ").join("-")}`}
                href={tool.to}
                rel="nooopener noreferrer"
                target="_blank"
                style={{
                  marginBottom: index < tools.length - 1 ? "1.5rem" : 0,
                }}
              >
                <Heading
                  style={headerStyle}
                  content={
                    <Row>
                      {tool.title}
                      <FiExternalLink
                        size={12}
                        style={{ marginLeft: ".5rem", opacity: ".5" }}
                      />
                    </Row>
                  }
                />
                <Text as="h3" style={textStyle} content={tool.description} />
              </StyledA>
            )
          )}
        </Box>
      </Box>
    </SectionLayout>
  );
};

// ToolsSection style props
ToolsSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

// ToolsSection default style
ToolsSection.defaultProps = {
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
  },
  // feature col default style
  col: {
    width: [3 / 4, 1, 1, 3 / 4],
    color: "#525f7f",
  },

  textStyle: {
    fontSize: "18px",
    color: "#1D0F68",
    margin: 0,
  },

  headerStyle: {
    fontSize: "24px",
    fontWeight: "800",
    lineHeight: "140%",
    margin: 0,
    marginBottom: "0,5rem",
    color: "rgb(62, 33, 222)",
  },
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const StyledLink = styled(Link)`
  padding: 1.5rem;
  background: #fff;
  border-radius: 8px;
  border: solid 2px rgba(62, 33, 222, 0.1);

  &:hover {
    ${"" /* box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); */}
    border: solid 2px rgba(62, 33, 222,1);
  }
`;

const StyledA = styled.a`
  padding: 1.5rem;
  background: #fff;
  border-radius: 8px;
  border: solid 2px rgba(62, 33, 222, 0.1);

  &:hover {
    ${"" /* box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); */}
    border: solid 2px rgba(62, 33, 222,1);
  }
`;

export default ToolsSection;
