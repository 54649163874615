import React from "react";
import ToolsSection from "../../containers/ToolsSection";
import MainLayout from "../../components/MainLayout";

export default () => {
  return (
    <MainLayout
      hasGradient
      title="Tools"
      pathname="/tools"
      description="VisualEyes is all about improving the workflow for designers, product managers and marketeers. Here are some tools that can help you get your job done"
    >
      <ToolsSection />
    </MainLayout>
  );
};
